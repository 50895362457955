@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/_variables";
@import "theme-color.scss";
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&family=Nunito:ital,wght@0,200..1000;1,200..1000&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');$custom-colors: (
  "dark-green": $dark-green,
  "light-green": $light-green,
  "light-grey": $light-grey,
  "grey": $grey,
  "light-blue-grey": $light-blue-grey,
  "white": $white,
  "gray": $gray-100,

  "snow-white": $snow-white,
);

$theme-colors: map-merge($theme-colors, $custom-colors);

@import "../node_modules/bootstrap/scss/bootstrap";

body {
  font-family:  "Open Sans", sans-serif;
}
a {
  text-decoration: none;
}

.light-sec-space {
  margin: 70px auto;
}

.grey-sec-space {
  border-top: 70px solid $light-grey;
  border-bottom: 70px solid $light-grey;
}

.li-before::before {
  content: "/";
  color: $light-green;
  margin: 0 0.5rem;
}

.path-text {
  color: $light-green;
}

.path-text:hover {
  cursor: pointer;
}

.pointer:hover {
  cursor: pointer;
}

.card-border-top-line {
  border-top: 2px solid $dark-green;
}

.card-min-height {
  min-height: 450px;
}

.star-card-height {
  height: 400px;
}

.tab-nav {
  color: $grey;
  border-bottom: 1px solid $light-blue-grey;
  &:hover {
    cursor: pointer;
    border-bottom: 1px solid $dark-green;
  }
  &.active {
    background: $light-grey;
    color: $dark-green;
    border-bottom-color: transparent;
  }
}

@import "../../../theme-color.scss";

.navbar-brand {
  font-size: 1.7rem;
}

.logo-style {
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
}

.custom-navbar {
  border-bottom: 1px solid $dark-green;
}

.toggle {
  color: $dark-green;
}

.nav-dropdown-title .dropdown-toggle {
  color: $dark-green;
}

.nav-dropdown-title .dropdown-toggle:hover {
  color: $dark-green;
  text-decoration: underline;
}

.link-style:hover {
  text-decoration: underline;
}

.custom-nav-dropdown .dropdown-item {
  cursor: pointer;
}

.custom-nav-dropdown .dropdown-item:active {
  background-color: transparent;
}
